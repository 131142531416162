import { render, staticRenderFns } from "./assess-end-year-details.vue?vue&type=template&id=5a176887"
import script from "./assess-end-year-details.vue?vue&type=script&lang=js"
export * from "./assess-end-year-details.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports